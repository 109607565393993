import React from "react";
import { useNavigate } from "react-router-dom";
import employesDashboardImage from "../images/landingPage/employer dashboard.PNG";
import testProcessImage from "../images/landingPage/test process.png";
import companyLogo from "../images/Logo/whiteLogo.png";

// icons
import { LuWorkflow, LuMonitorCheck } from "react-icons/lu";
import { MdDashboard, MdMarkEmailRead, MdOutlinePreview } from "react-icons/md";
import { ImProfile } from "react-icons/im";

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <div className="font-sans">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-r from-blue-600 to-purple-500 text-white py-20 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-t from-black opacity-30 pointer-events-none"></div>
        <div className="text-right px-10 mb-10 flex justify-between items-center relative z-10">
          <div>
            <img src={companyLogo} alt="Grapesoft Logo" className="w-20" />
          </div>
          <div className="space-x-4">
            <button
              onClick={() => navigate("/login")}
              className="bg-white text-black font-bold py-2 px-6 rounded-full hover:bg-gray-200 transition-all duration-300"
            >
              Login
            </button>
            <button
              onClick={() => navigate("/signup")}
              className="bg-black text-white font-bold py-2 px-6 rounded-full hover:bg-gray-700 transition-all duration-300"
            >
              Sign Up
            </button>
          </div>
        </div>
        <div className="container mx-auto text-center relative z-10">
          <h1 className="text-6xl font-extrabold mb-6 text-white">
            Revolutionize Your Recruitment with
          </h1>
          <h1 className="text-white bg-black text-6xl font-extrabold mb-6 py-2 px-4 inline-block rounded-lg shadow-xl">
            Grapesoft Hiring App
          </h1>
          <p className="text-xl mb-8">
            Streamline your hiring process from application to acceptance with
            advanced automation and real-time evaluation.
          </p>
          <button
            onClick={() => navigate("/signup")}
            className="bg-white text-purple-800 font-bold py-3 px-8 rounded-full hover:bg-gray-100 transition-all duration-300"
          >
            Start Free Trial
          </button>
          <div className="mt-10 flex justify-center relative z-10">
            <img
              src={employesDashboardImage}
              alt="Employer Dashboard"
              className="rounded-lg shadow-2xl transform hover:scale-110 transition-transform duration-300"
            />
          </div>
        </div>
      </section>

      {/* Features and Benefits */}
      <section className="bg-gray-50 py-20">
        <div className="container mx-auto text-center">
          <h2 className="text-5xl font-extrabold mb-12 text-gray-800">
            Features and Benefits
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {/* Feature 1 */}
            <div className="relative bg-white shadow-xl rounded-lg p-10 transform hover:scale-105 transition-transform duration-300 hover:shadow-2xl">
              <div className="absolute inset-0 bg-gradient-to-br from-purple-100 to-blue-100 opacity-30 rounded-lg pointer-events-none"></div>
              <div className="relative z-10">
                <div className="flex justify-center mb-4">
                  <LuWorkflow className="text-5xl text-purple-700" />
                </div>
                <h3 className="text-2xl font-bold text-purple-600 mb-4">
                  Automated Candidate Links
                </h3>
                <p className="text-gray-600">
                  Send personalized interview links automatically. Save time and
                  reduce manual errors.
                </p>
              </div>
            </div>
            {/* Feature 2 */}
            <div className="relative bg-white shadow-xl rounded-lg p-10 transform hover:scale-105 transition-transform duration-300 hover:shadow-2xl">
              <div className="absolute inset-0 bg-gradient-to-br from-purple-100 to-blue-100 opacity-30 rounded-lg pointer-events-none"></div>
              <div className="relative z-10">
                <div className="flex justify-center mb-4">
                  <LuMonitorCheck className="text-5xl text-purple-700" />
                </div>
                <h3 className="text-2xl font-bold text-purple-600 mb-4">
                  Real-Time Monitoring
                </h3>
                <p className="text-gray-600">
                  Ensure integrity with camera and screen recording during
                  tests, making your decision process foolproof.
                </p>
              </div>
            </div>
            {/* Feature 3 */}
            <div className="relative bg-white shadow-xl rounded-lg p-10 transform hover:scale-105 transition-transform duration-300 hover:shadow-2xl">
              <div className="absolute inset-0 bg-gradient-to-br from-purple-100 to-blue-100 opacity-30 rounded-lg pointer-events-none"></div>
              <div className="relative z-10">
                <div className="flex justify-center mb-4">
                  <MdDashboard className="text-5xl text-purple-700" />
                </div>
                <h3 className="text-2xl font-bold text-purple-600 mb-4">
                  Admin Dashboard
                </h3>
                <p className="text-gray-600">
                  Manage all candidate data, test results, and hiring decisions
                  from a single, intuitive dashboard.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* How It Works */}
      <section className="py-20 bg-gradient-to-r from-purple-800 to-blue-600 text-white">
        <div className="container mx-auto text-center">
          <h2 className="text-5xl font-extrabold mb-12">How It Works</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="relative bg-white shadow-xl rounded-lg p-10 text-gray-800 transform hover:scale-105 transition-transform duration-300 hover:shadow-2xl">
              <div className="absolute inset-0 bg-gradient-to-br from-purple-100 to-blue-100 opacity-30 rounded-lg pointer-events-none"></div>
              <div className="relative z-10">
                <div className="flex justify-center mb-4">
                  <MdMarkEmailRead className="text-5xl text-purple-700" />
                </div>
                <h3 className="text-xl font-bold mb-4">
                  Step 1: Candidate receives a link
                </h3>
              </div>
            </div>
            <div className="relative bg-white shadow-xl rounded-lg p-10 text-gray-800 transform hover:scale-105 transition-transform duration-300 hover:shadow-2xl">
              <div className="absolute inset-0 bg-gradient-to-br from-purple-100 to-blue-100 opacity-30 rounded-lg pointer-events-none"></div>
              <div className="relative z-10">
                <div className="flex justify-center mb-4">
                  <ImProfile className="text-5xl text-purple-700" />
                </div>
                <h3 className="text-xl font-bold mb-4">
                  Step 2: Candidate completes profile and tests
                </h3>
              </div>
            </div>
            <div className="relative bg-white shadow-xl rounded-lg p-10 text-gray-800 transform hover:scale-105 transition-transform duration-300 hover:shadow-2xl">
              <div className="absolute inset-0 bg-gradient-to-br from-purple-100 to-blue-100 opacity-30 rounded-lg pointer-events-none"></div>
              <div className="relative z-10">
                <div className="flex justify-center mb-4">
                  <MdOutlinePreview className="text-5xl text-purple-700" />
                </div>
                <h3 className="text-xl font-bold mb-4">
                  Step 3: Admin reviews results
                </h3>
              </div>
            </div>
          </div>
          <div className="mt-10 flex justify-center">
            <img
              src={testProcessImage}
              alt="Test Process"
              className="rounded-lg shadow-2xl transform hover:scale-105 transition-transform duration-300"
            />
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section className="bg-blue-800 text-white py-20">
        <div className="container mx-auto text-center">
          <h2 className="text-5xl font-extrabold mb-12 break-words">
            Testimonials & Success Stories
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="bg-white text-gray-900 shadow-xl rounded-lg p-10 transform hover:scale-105 transition-transform duration-300">
              <p className="text-xl italic">
                "Using Grapesoft has cut our hiring time in half and improved
                our candidate quality dramatically."
              </p>
              <p className="text-right font-bold">- HR Manager</p>
            </div>
            <div className="bg-white text-gray-900 shadow-xl rounded-lg p-10 transform hover:scale-105 transition-transform duration-300">
              <p className="text-xl italic">
                "95% of users report increased efficiency in hiring within the
                first three months."
              </p>
              <p className="text-right font-bold">- CEO, Grapesoft</p>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-20 bg-gray-100">
        <div className="container mx-auto text-center">
          <h2 className="text-5xl font-extrabold mb-12 break-words">
            Frequently Asked Questions
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="bg-white shadow-xl rounded-lg p-10 transform hover:scale-105 transition-transform duration-300">
              <h3 className="text-2xl font-bold mb-4">
                What types of tests can I include?
              </h3>
              <p className="text-gray-600">
                You can include a variety of skill assessments, personality
                tests, and more.
              </p>
            </div>
            <div className="bg-white shadow-xl rounded-lg p-10 transform hover:scale-105 transition-transform duration-300">
              <h3 className="text-2xl font-bold mb-4">
                How secure is the data collected?
              </h3>
              <p className="text-gray-600">
                All data is encrypted and stored securely, ensuring compliance
                with privacy laws.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-8">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 Grapesoft Hiring App. All Rights Reserved.</p>
          <div className="flex justify-center space-x-4 mt-4">
            <div
              onClick={() => navigate("/signup")}
              className="hover:text-gray-400 cursor-pointer"
            >
              User Register
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
